export default function({ store, redirect, route}) {
  const user = store.state.user
  // console.log('checkin auth | user', user)
  // console.log('route.query.ref', route.query.ref)
  if (user) {
    // if (route.query.ref) {
    //   redirect(route.query.ref)
    // } else {
    //   redirect(`/`)
    // }
    if (route.query.ref && route.query.ref.match(/\/vmeste\/[a-z0-9]{24}/)) {
      redirect(route.query.ref)
    } else {
      redirect(`/chat`)
    }
  }
}
