export default {
  methods: {
    translateCountry(countries = []) {
      // console.log('countries', countries)

      const countryList = [
        { ru: 'ОАЭ', en: 'UAE' },
        { ru: 'Франция', en: 'France' },
        { ru: 'Китай', en: 'China' },
        { ru: 'Япония', en: 'Japan' },
        { ru: 'США', en: 'USA' },
        { ru: 'Австрия', en: 'Austria' },
        { ru: 'Германия', en: 'Germany' },
        { ru: 'Кипр', en: 'Cyprus' },
        { ru: 'Ливан', en: 'Lebanon' },
        { ru: 'Россия', en: 'Russia' },
        { ru: 'Дания', en: 'Denmark' },
        { ru: 'Корея Южная', en: 'South Korea' },
        { ru: 'Турция', en: 'Turkey' },
        { ru: 'Гонконг', en: 'Hong Kong' },
        { ru: 'Великобритания', en: 'United Kingdom' },
        { ru: 'Бельгия', en: 'Belgium' },
        { ru: 'Люксембург', en: 'Luxembourg' },
        { ru: 'Индия', en: 'India' },
        { ru: 'Канада', en: 'Canada' },
        { ru: 'Казахстан', en: 'Kazakhstan' },
        { ru: 'Италия', en: 'Italy' },
        { ru: 'Аргентина', en: 'Argentina' },
        { ru: 'Испания', en: 'Spain' },
        { ru: 'Украина', en: 'Ukraine' },
        { ru: 'Бразилия', en: 'Brazil' },
        { ru: 'Польша', en: 'Poland' }
      ]
      
      const arr = countries.map(el => {
        const findCountry = countryList.find(g => g.ru === el.trim())

        return findCountry ? findCountry.en : ''
      })

      return arr.join(', ')
    },
    translateGenre(genres = []) {
      const genreList = [
        { 'ru': 'боевик', 'en': 'action' },
        { 'ru': 'военный', 'en': 'war' },
        { 'ru': 'драма', 'en': 'drama' },
        { 'ru': 'комедия', 'en': 'comedy' },
        { 'ru': 'музыка', 'en': 'music' },
        { 'ru': 'семейный', 'en': 'family' },
        { 'ru': 'фантастика', 'en': 'science fiction' },
        { 'ru': 'мультфильм', 'en': 'animation' },
        { 'ru': 'фэнтези', 'en': 'fantasy' },
        { 'ru': 'мелодрама', 'en': 'romance' },
        { 'ru': 'аниме', 'en': 'anime' },
        { 'ru': 'детектив', 'en': 'detective' },
        { 'ru': 'криминал', 'en': 'crime' },
        { 'ru': 'триллер', 'en': 'thriller' },
        { 'ru': 'приключения', 'en': 'adventure' },
        { 'ru': 'вестерн', 'en': 'western' },
        { 'ru': 'история', 'en': 'history' },
        { 'ru': 'короткометражка', 'en': 'short film' },
        { 'ru': 'документальный', 'en': 'documentary' },
        { 'ru': 'реальное ТВ', 'en': 'reality TV' },
        { 'ru': 'ужасы', 'en': 'horror' },
        { 'ru': 'спорт', 'en': 'sport' },
        { 'ru': 'биография', 'en': 'biography' },
        { 'ru': 'ток-шоу', 'en': 'talk show' },
        { 'ru': 'мюзикл', 'en': 'musical' }
      ]

      const arr = genres.map(el => {
        const findGenre = genreList.find(g => g.ru === el.trim())

        return findGenre ? findGenre.en : ''
      })

      return arr.join(', ')
    },
    filmPoster(film) {
      let poster = '/static_files/no-poster.png'

      try {
        if (film.kinopoisk_id) {
          poster = `https://st.kp.yandex.net/images/film_iphone/iphone360_${film.kinopoisk_id}.jpg`
        } else if (film.material_data && film.material_data.poster && !film.material_data.poster.includes('no-poster')) {
          poster = film.material_data.poster
        } else if (film.poster) {
          poster = film.poster
        }
        return poster
      } catch (error) {
        console.error('[filmPoster]', error)
        return poster
      }
    },
    lazyLoadImgs() {
      // console.log('lazyLoadImgs')

      const list = document.querySelectorAll('img.lazy')
      const docHeight = document.documentElement.clientHeight
      // const docWidth = document.documentElement.clientWidth
      if (list) {
        list.forEach(img => {
          const topPosition = docHeight - img.getBoundingClientRect().top
          // const leftPosition =  img.getBoundingClientRect().left - docWidth
          if (
            +topPosition > -300 &&
            // +leftPosition < 0 &&
            // +topPosition > -100 &&
            // +leftPosition < 100 &&
            (
              img.src.includes('data:image') ||
              img.src.includes('no-poster.png') ||
              img.src.includes('noavatar.svg')
            )
          ) {
            img.src = '/'
            img.src = img.dataset.src
          }
        })
      }
    },
    postUrl(id, title) {
      if (title) {
        title = title.indexOf('(') >= 0
          ? title.slice(0, title.indexOf('(') + 1)
          : title
        const str = title
          .trim()
          .toLowerCase()
          .replace(/[^a-zа-я0-9]/g, ' ')
          .replace(/ {2,}/g, ' ')
        const c = {
          а: 'a',
          б: 'b',
          в: 'v',
          г: 'g',
          д: 'd',
          е: 'e',
          ё: 'jo',
          ж: 'zh',
          з: 'z',
          и: 'i',
          й: 'j',
          к: 'k',
          л: 'l',
          м: 'm',
          н: 'n',
          о: 'o',
          п: 'p',
          р: 'r',
          с: 's',
          т: 't',
          у: 'u',
          ф: 'f',
          х: 'h',
          ц: 'c',
          ч: 'ch',
          ш: 'sh',
          щ: 'shch',
          ъ: '',
          ы: 'y',
          ь: '',
          э: 'e',
          ю: 'ju',
          я: 'ja',
          ' ': '-',
          ';': '',
          ':': '',
          ',': '',
          '—': '-',
          '–': '-',
          '.': '',
          '«': '',
          '»': '',
          '"': '',
          "'": '',
          '@': ''
        }
        let newStr = ''
        for (let i = 0; i < str.length; i++) {
          const ch = str.charAt(i)
          newStr += ch in c ? c[ch] : ch
        }
        newStr = newStr.replace(/-$/g, '')
        return `${id}-${newStr}`
      } else {
        return `${id}`
      }
    },
    personName(name) {
      const str = name
        .trim()
        .toLowerCase()
        .replace(/[^a-zа-я0-9]/g, ' ')
        .replace(/ {2,}/g, ' ')
      const c = {
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'jo',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'j',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'c',
        ч: 'ch',
        ш: 'sh',
        щ: 'shch',
        ъ: '',
        ы: 'y',
        ь: '',
        э: 'e',
        ю: 'ju',
        я: 'ja',
        ' ': '-',
        ';': '',
        ':': '',
        ',': '',
        '—': '-',
        '–': '-',
        '.': '',
        '«': '',
        '»': '',
        '"': '',
        "'": '',
        '@': ''
      }
      let newStr = ''
      for (let i = 0; i < str.length; i++) {
        const ch = str.charAt(i)
        newStr += ch in c ? c[ch] : ch
      }
      newStr = newStr.replace(/-$/g, '')
      return newStr
    },
  }
}
