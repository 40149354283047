export default function ({ route, redirect, app }) {
  // console.log('route.meta', route.meta)

  // console.log('app', app.i18n.locales)
  
  const { i18n } = route.meta && route.meta.length ? route.meta[0] : {};

  // Если маршрут не поддерживает мультиязычность (i18n: false), перенаправляем на соответствующий маршрут без префикса языка
  if (i18n === false) {
    const codes = app.i18n.locales.map(el => el.code)

    // console.log('codes', codes)

    let path = route.path

    codes.forEach(el => {
      path = path.replace(`/${el}/`, '/')
    })

    // path = path.replace('//', '/')

    // console.log('path', path)

    if (route.path !== path) {
      return redirect(path)
    }
  }
}
