import locale18a23ec0 from '../../lang/ru-RU.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ru"},
  vueI18nLoader: false,
  locales: [{"code":"uk","iso":"uk-UA","name":"Український","file":"uk-UA.js"},{"code":"be","iso":"be-BY","name":"Білоруський","file":"be-BY.js"},{"code":"en","iso":"en-US","name":"English","file":"en-US.js"},{"code":"ru","iso":"ru-RU","name":"Русский","file":"ru-RU.js"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/root/www/unotalone/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"uk","iso":"uk-UA","name":"Український","file":"uk-UA.js"},{"code":"be","iso":"be-BY","name":"Білоруський","file":"be-BY.js"},{"code":"en","iso":"en-US","name":"English","file":"en-US.js"},{"code":"ru","iso":"ru-RU","name":"Русский","file":"ru-RU.js"}],
  localeCodes: ["uk","be","en","ru"],
  additionalMessages: [],
}

export const localeMessages = {
  'ru-RU.js': () => Promise.resolve(locale18a23ec0),
  'uk-UA.js': () => import('../../lang/uk-UA.js' /* webpackChunkName: "lang-uk-UA.js" */),
  'be-BY.js': () => import('../../lang/be-BY.js' /* webpackChunkName: "lang-be-BY.js" */),
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
}
